body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 14px;
}

table,
.divTable {
  border: 0.5px solid lightgray;
  width: fit-content;
  height: 100%;
}

.tr {
  display: flex;
}

tr,
.tr {
  width: fit-content;
  height: 30px;
}

th,
.th,
td,
.td {
  /* box-shadow: inset 0 0 0 0.5px lightgray; */
  padding: 0.25rem;
}

th,
.th {
  padding: 2px 4px;
  position: relative;
  font-weight: bold;
  text-align: center;
  height: 60px;
  border-left: 0.5px solid lightgray; /* Left border for header */
  border-right: 0.5px solid lightgray; /* Right border for header */
  background-color: rgb(229, 229, 229); /* Or any color to match your design */
}

td,
.td {
  height: 30px;
  border-top: 0.5px solid lightgray; /* Top border for rows */
  border-bottom: 0.5px solid lightgray; /* Bottom border for rows */
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}

.table-container {
  max-height: 500px; /* Adjust as needed */
  overflow-y: auto;
}
.table-container thead th {
  position: sticky;
  top: 0;
  background-color: rgb(229, 229, 229); /* Or any color to match your design */
  z-index: 10;
}
